import {Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
  langLabel: string
  lang: string
}

export const Language: React.FC<Props> = ({lang, langLabel}) => {
  const {i18n} = useTranslation()
  if (i18n.language.substring(0, lang.length) === lang) {
    return <Text>{langLabel}</Text>
  }
  const style: IStyle = {
    cursor: 'pointer',
    textDecoration: 'underline',
  }
  return (
    <Text style={style} htmlAttrs={{onClick: () => i18n.changeLanguage(lang)}}>
      {langLabel}
    </Text>
  )
}
