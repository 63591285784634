import {Flex, Text} from '@indoqa/style-system'
import {IStyle} from 'fela'
import {Field, FieldProps, FieldValidator, FormikErrors, FormikTouched, getIn} from 'formik'
import * as React from 'react'
import {StyleFunction, useFela} from 'react-fela'
import {reach, Schema} from 'yup'
import {Theme} from '../../app/theme'

export interface Props {
  name: string
  label: string
  disabled?: boolean
  errors: FormikErrors<{}>
  touched: FormikTouched<{}>
  validationSchema?: Schema<any>
  validator?: FieldValidator
  required?: boolean
}

const Label: React.FC = ({children}) => {
  const {css} = useFela()
  const style: IStyle = {
    display: 'inline-block',
    width: '200',
  }
  return (
    <label className={css(style)}>
      {children}
    </label>
  )
}

interface InputFieldProps {
  fieldProps: FieldProps<any>
  autoComplete: string
  hasError: boolean
  disabled?: boolean
}

interface InputFieldStyleProps extends IStyle {
  ':focus': IStyle
}

const InputField: React.FC<InputFieldProps> = ({children, disabled, fieldProps, autoComplete, hasError}) => {
  const {css} = useFela()
  const style: InputFieldStyleProps = {
    borderStyle: 'solid',
    borderWidth: 1,
    padding: 4,
    borderColor: hasError ? 'red' : 'grey',
    outline: 'none',
    boxShadow: 'none',
    width: 300,
    transition: 'all 0.30s ease-in-out',
    ':focus': {
      boxShadow: hasError ? '0 0 5px rgba(255, 0, 0, 1)' : '0 0 5px rgba(81, 203, 238, 1)',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: hasError ? 'rgba(255, 0, 0, 0.5)' : 'rgba(81, 203, 238, 1)',
    },
  }
  return <input autoComplete={autoComplete} {...fieldProps.field} disabled={disabled} className={css(style)} />
}

const ErrorMessage: React.FC = ({children}) => {
  const style: StyleFunction<Theme> = ({theme}) => ({
    paddingLeft: 205,
    color: 'red',
  })
  return (
    <Text style={style}>
      {children}
    </Text>
  )
}

const renderLabel = (label: string, showRequiredMarker?: boolean) => {
  return <Label>{label} {showRequiredMarker && <span>*</span>}</Label>
}

const renderField = (name: string, hasError: boolean, disabled?: boolean, validator?: FieldValidator) => {
  return (
    <Field name={name} validate={validator}>
      {(fieldProps: FieldProps<any>) => (
        <InputField fieldProps={fieldProps} hasError={hasError} autoComplete="off" disabled={disabled} />
      )}
    </Field>
  )
}

const renderError = (name: string, hasError: boolean, errors: FormikErrors<any>) => {
  if (!hasError) {
    return null
  }
  return <ErrorMessage>{getIn(errors, name)}</ErrorMessage>
}

const showRequiredMarker = (schema: Schema<any> | undefined, name: string) => {
  if (!schema) {
    return false
  }

  const fieldSchema = reach(schema, name, {}).describe()

  if (!fieldSchema) {
    return false
  }

  return fieldSchema.tests.find(test => test.name === 'required') !== undefined
}

const FormRow = ({name, label, disabled, errors, touched, validationSchema, validator, required}: Props) => {
  const hasError = getIn(touched, name) && getIn(errors, name)

  return (
    <Flex pb={1} alignItems="baseline">
      {renderLabel(label, required || showRequiredMarker(validationSchema, name))}
      {renderField(name, hasError, disabled, validator)}
      {renderError(name, hasError, errors)}
    </Flex>
  )
}

export default FormRow
