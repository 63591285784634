import {PStyle, Text} from '@indoqa/style-system'
import * as React from 'react'

interface Props {
  appName: string,
}

export const Brand = ({appName}: Props) => {
  const baseStyle: PStyle = {
    fontFamily: 'Graphik',
    fontWeight: 900,
    fontSize: '1.3rem',
  }
  const companyNameStyle: PStyle = {
    color: '#ee6365',
  }
  const appNameStyle: PStyle = {
    color: '#777',
  }
  return (
    <>
      <Text style={[baseStyle, companyNameStyle]}>indoqa.</Text>
      <Text style={[baseStyle, appNameStyle]}>{appName}</Text>
    </>
  )
}
