import * as React from 'react'
import {Box, Flex} from '@indoqa/style-system'
import {ContentPanel} from './ContentPanel'

export interface Props {
  previous?: React.ReactElement
  next?: React.ReactElement
}

export const PagingBar = ({previous, next}: Props) => {
  return (
    <ContentPanel>
      <Flex mt={3} justifyContent="space-between">
        <Box>
          {previous}
        </Box>
        <Box ml={4}>
          {next}
        </Box>
      </Flex>
    </ContentPanel>
  )
}
