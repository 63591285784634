import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../app/theme'

interface Props {
  text: string,
}

export const TermsText: React.FC<Props> = ({text}) => {
  const {css} = useFela<Theme>()
  const style: IStyle = {
    whiteSpace: 'pre-wrap',
    fontSize: '90%',
  }
  return (
    <pre className={css(style)}>
      {text}
    </pre>
  )
}
