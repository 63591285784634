import * as React from 'react'
import {Language, useCurrentLanguage} from '../../commons/hooks/useCurrentLanguage'

export const CFAcceptTermsOfUser: React.FC = () => {
  const lang = useCurrentLanguage()
  if (lang === Language.EN) {
    return <p>You confirm that you accept the following terms of use:</p>
  }
  return <p>Hiermit bestätigen Sie, dass Sie die folgenden Software&shy;überlassungs&shy;bedingungen akzeptieren:</p>
}
