"use strict";
// tslint:disable
/**
 * Indoqa Licensing
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *
 * @export
 * @enum {string}
 */
var ValidityUnit;
(function (ValidityUnit) {
    ValidityUnit["DAY"] = "DAY";
    ValidityUnit["YEAR"] = "YEAR";
})(ValidityUnit = exports.ValidityUnit || (exports.ValidityUnit = {}));
