import {Box, Flex, NavFlex, PStyle} from '@indoqa/style-system'
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {Language} from '../../checkout/components/Language'
import {Brand} from './Brand'

import indoqaLogo from './indoqa.png'

interface Props {
}

interface NavbarStyle extends PStyle {
  '& img': PStyle
}

export const Navbar: React.FC<Props> = ({children}) => {
  const {t} = useTranslation('steps')
  const style: NavbarStyle = {
    height: 76,
    color: 'white',
    backgroundImage: 'linear-gradient(133deg,#000 0%,#4a0809 100%)',
    '& img': {
      width: 50,
      height: 50,
    },
  }
  return (
    <NavFlex fullWidth nowrap style={style} justifyContent="center">
      <Flex width={1200} alignItems="center" px={2} justifyContent="space-between">
        <Flex nowrap alignItems="center">
          <Box pr={1}>
            <img src={indoqaLogo} alt="Indoqa Logo" />
          </Box>
          <Brand appName="licensing" />
        </Flex>
        <Box px={2}>
          {t('changeLanguage')}:&nbsp;
          <Language lang="en" langLabel="EN" /> | <Language lang="de" langLabel="DE" />
        </Box>
      </Flex>
    </NavFlex>
  )
}
