import {Contact, Offer, Product, ValidatedCheckout} from 'licensing-client-typescript'
import {
  BuySoftware,
  CreateCheckout,
  CreateCheckoutError,
  CreateCheckoutSuccess,
  LoadOffer,
  LoadOfferError,
  LoadOfferSuccess,
  LoadTerms,
  LoadTermsError,
  LoadTermsSuccess,
  SelectProducts,
  SetNewsletterAccepted,
  SetTermsAccepted,
  StoreContactDetails,
} from './checkout.actions'

export type CheckoutAction =
  | SelectProducts
  | LoadOffer
  | LoadOfferSuccess
  | LoadOfferError
  | StoreContactDetails
  | CreateCheckout
  | SetNewsletterAccepted
  | SetTermsAccepted
  | CreateCheckoutSuccess
  | CreateCheckoutError
  | BuySoftware
  | LoadTerms
  | LoadTermsSuccess
  | LoadTermsError

export enum CheckoutActionKeys {
  SELECT_PRODUCT = 'checkout/SELECT_PRODUCTS',
  LOAD_OFFER = 'checkout/LOAD_OFFER',
  LOAD_OFFER_SUCCESS = 'checkout/LOAD_OFFER_SUCCESS',
  LOAD_OFFER_ERROR = 'checkout/LOAD_OFFER_ERROR',
  STORE_USER_DATA = 'checkout/STORE_USER_DATA',
  CREATE_CHECKOUT = 'checkout/CREATE_CHECKOUT',
  CREATE_CHECKOUT_SUCCESS = 'checkout/CREATE_CHECKOUT_SUCCESS',
  CREATE_CHECKOUT_ERROR = 'checkout/CREATE_CHECKOUT_ERROR',
  SET_NEWSLETTER_ACCEPTED = 'checkout/SET_NEWSLETTER_ACCEPTED',
  SET_TERMS_ACCEPTED = 'checkout/SET_TERMS_ACCEPTED',
  BUY_SOFTWARE = 'checkout/BUY_SOFTWARE',
  LOAD_TERMS = "checkout/LOAD_TERMS",
  LOAD_TERMS_SUCCESS = "checkout/LOAD_TERMS_SUCCESS",
  LOAD_TERMS_ERROR = "checkout/LOAD_TERMS_ERROR",
}

export type CheckoutState = {
  offerId: string | null
  offer: Offer | null
  selectedProducts: { [id: string]: Product }
  contact: Contact
  validatedCheckout: ValidatedCheckout | null
  termsAccepted: boolean
  newsletterAccepted: boolean
  errorMessage: string | null
  terms: string | null
  termsErrorMessage: string | null
}
