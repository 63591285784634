import * as React from 'react'
import {Language, useCurrentLanguage} from '../../commons/hooks/useCurrentLanguage'
import {ContentPanel} from '../components/ContentPanel'

export const CFProductsSelection: React.FC = () => {
  const lang = useCurrentLanguage()
  if (lang === Language.EN) {
    return (
      <ContentPanel>
        <h3>Selection</h3>
        <p>
          Please choose from the following list which software products you are interested in:
        </p>
      </ContentPanel>
    )
  }
  return (
    <ContentPanel>
      <h3>Auswahl</h3>
      <p>
        Bitte wählen Sie aus der folgenden Liste aus, an welchen Software-Produkten Sie Interesse haben:
      </p>
    </ContentPanel>
  )
}
