import {Box, Flex, PStyle} from '@indoqa/style-system'
import useEventListener from '@use-it/event-listener'
import {BodyScrollOptions, disableBodyScroll, enableBodyScroll} from 'body-scroll-lock'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../app/theme'

interface Props {
  show: boolean
  onRequestClose: () => void
  header?: JSX.Element
  footer?: JSX.Element
  headerHeight?: number
  footerHeight?: number
  windowStyle?: PStyle
  contentStyle?: PStyle
  veilStyle?: PStyle
}

const bodyScrollOptions: BodyScrollOptions = {
  reserveScrollBarGap: true,
}

export const ModalWindow: React.FC<Props> = ({
  show,
  header,
  footer,
  headerHeight = 35,
  footerHeight = 45,
  windowStyle,
  contentStyle,
  veilStyle,
  onRequestClose,
  children,
}) => {
  const {theme} = useFela<Theme>()
  const modalWindowRef = React.useRef<HTMLDivElement>(null)

  if (modalWindowRef.current) {
    if (show) {
      disableBodyScroll(modalWindowRef.current, bodyScrollOptions)
    } else {
      enableBodyScroll(modalWindowRef.current)
    }
  }

  useEventListener<React.KeyboardEvent>('keydown', (e) => {
    if (e.key === 'Escape') {
      onRequestClose()
    }
  })

  const mergedWindowStyle: PStyle = {
    height: '90vh',
    width: '80vw',
    maxWidth: 750,
    backgroundColor: '#FFF',
    boxShadow: theme.shadows.modalWindow,
    ...windowStyle,
  }
  const mergedContentStyle: PStyle = {
    overflowY: 'auto',
    height: `calc(100% - ${headerHeight}px - ${footerHeight}px)`,
    textAlign: 'left',
    ...contentStyle,
  }
  const mergedVeilStyle: PStyle = {
    display: show ? 'flex' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    backgroundColor: theme.colors.bgModalWindowVeil,
    ...veilStyle,
  }
  return (
    <Flex center style={mergedVeilStyle} onClick={() => onRequestClose()}>
      <Flex style={mergedWindowStyle} direction="column" onClick={(e) => e.stopPropagation()} innerRef={modalWindowRef}>
        <Flex fullWidth bg={theme.colors.bgModalWindowHeader} alignItems="center" height={headerHeight} bold>
          {header}
        </Flex>
        <Box grow={1} style={mergedContentStyle}>
          {children}
        </Box>
        <Flex fullWidth bg={theme.colors.bgModalWindowFooter} alignItems="center" height={footerHeight}>
          {footer}
        </Flex>
      </Flex>
    </Flex>
  )
}
