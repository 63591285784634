import {Box, Flex, FooterFlex, PStyle} from '@indoqa/style-system'
import * as React from 'react'
import {useTranslation} from 'react-i18next'

interface Props {
}

interface LinkStyle extends PStyle {
  '& a': PStyle
}

export const Footer: React.FC<Props> = ({children}) => {
  const {t} = useTranslation('others')
  const style: PStyle = {
    backgroundImage: 'linear-gradient(133deg,#000 0%,#4a0809 100%)',
    color: '#FFF',
  }
  const companyNameStyle: PStyle = {
    fontFamily: 'Cooper',
    fontSize: '1.3rem',
  }
  const linkStyle: LinkStyle = {
    '& a': {
      color: '#FFF',
      textDecoration: 'none',
    },
  }
  return (
    <FooterFlex style={style} py={3} justifyContent="center">
      <Flex width={1200} justifyContent="space-between" px={2}>
        <Box>
          <Box style={companyNameStyle}>Indoqa Software Design und Beratung GmbH</Box>
          <Box>
            Opernring 1/E/639, 1010 Wien, Österreich
            <br />
            +43 (0)1 236 2828
            <br />
          </Box>
        </Box>
        <Flex textAlign="right" direction="column">
          <Box style={linkStyle}>
            <a href="https://www.indoqa.com/contact" target="_blank" rel="noopener noreferrer">
              {t('contact')}/{t('legalNotice')}
            </a>
          </Box>
          <Box style={linkStyle}>
            <a href="https://www.indoqa.com/datenschutzerklaerung" target="_blank" rel="noopener noreferrer">
              {t('privacyPolicy')}
            </a>
          </Box>
        </Flex>
      </Flex>
    </FooterFlex>
  )
}
