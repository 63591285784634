import {Form, Formik, FormikProps} from 'formik'
import {Contact} from 'licensing-client-typescript'
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import FormRow from '../../commons/components/FormRow'
import {storeContactDetails} from '../store/checkout.actions'
import {isCheckoutCompleted} from '../store/checkout.selectors'
import {ContentPanel} from './ContentPanel'
import {PagingBar} from './PagingBar'

interface Props {
  contact: Contact
  backLink: string
}

const createContactSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
  })

export const ContactDetailsDemoForm: React.FC<Props> = ({contact, backLink}) => {
  const {t} = useTranslation(['forms', 'steps'])
  const isCompleted = useSelector(isCheckoutCompleted)
  const contactSchema = createContactSchema()
  const dispatch = useDispatch()

  const submit = (contact: Contact) => {
    dispatch(storeContactDetails(contact))
  }

  return (
    <Formik onSubmit={submit} initialValues={contact} validationSchema={contactSchema}>
      {({errors, touched, submitForm}: FormikProps<Contact>) => {
        return (
          <ContentPanel>
            <Form>
              <FormRow
                name="name"
                label={t('name')}
                disabled={isCompleted}
                errors={errors}
                touched={touched}
                validationSchema={contactSchema}
              />
              <FormRow
                name="email"
                label={t('email')}
                disabled
                errors={errors}
                touched={touched}
                validationSchema={contactSchema}
              />
              <FormRow
                name="company.name"
                label={t('companyName')}
                disabled={isCompleted}
                errors={errors}
                touched={touched}
              />
              <PagingBar
                previous={
                  <Link to={backLink}>
                    {t('backTo')} &raquo;{t('steps:products')}&laquo;{' '}
                  </Link>
                }
                next={
                  <button type="button" onClick={submitForm}>
                    {t('proceed')}
                  </button>
                }
              />
            </Form>
          </ContentPanel>
        )
      }}
    </Formik>
  )
}
