import {useTranslation} from 'react-i18next'

export enum Language {
  EN = 'en',
  DE = 'de'
}

export const useCurrentLanguage = (): Language => {
  const {i18n} = useTranslation()
  if (i18n.language.substring(0, Language.DE.length) === Language.DE) {
    return Language.DE
  }
  return Language.EN
}
