import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../app/theme'

interface Props {
  onClick: () => void
}

export const LinkButton: React.FC<Props> = ({onClick, children}) => {
  const {css, theme} = useFela<Theme>()
  const style: IStyle = {
    border: 'none',
    backgroundColor: 'transparent',
    textDecoration: 'underline',
    cursor: 'pointer',
    color: theme.colors.primaryDark,
    margin: 0,
    padding: 0,
  }
  return (
    <button className={css(style)} onClick={onClick}>{children}</button>
  )
}
