import {Box} from '@indoqa/style-system'
import {Form, Formik, FormikProps} from 'formik'
import {Contact} from 'licensing-client-typescript'
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
import * as Yup from 'yup'
import FormRow from '../../commons/components/FormRow'
import {storeContactDetails} from '../store/checkout.actions'
import {isCheckoutCompleted} from '../store/checkout.selectors'
import {ContentPanel} from './ContentPanel'
import {PagingBar} from './PagingBar'

interface Props {
  contact: Contact
  backLink: string
}

const createContactSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().email().required(),
    company: Yup.object().shape({
      name: Yup.string().required(),
      address: Yup.string().required(),
      postalCode: Yup.string().required(),
      city: Yup.string().required(),
      country: Yup.string().required(),
      commercialRegNr: Yup.string(),
      vatRegNr: Yup.string(),
      invoiceContactName: Yup.string(),
      invoiceContactEmail: Yup.string().email(),
    }),
    poNumber: Yup.string(),
  })

export const ContactDetailsProdForm: React.FC<Props> = ({contact, backLink}) => {
  const {t} = useTranslation('forms')
  const {t: translateValidation} = useTranslation('validation')
  const [differentInvoiceContact, setDifferentInvoiceContact] = React.useState(!!contact.company?.invoiceContactName)
  const isCompleted = useSelector(isCheckoutCompleted)
  const dispatch = useDispatch()
  const contactSchema = createContactSchema()
  const submit = (contact: Contact) => {
    dispatch(storeContactDetails(contact))
  }
  const invoiceContactValidator = (value: any) => {
    if (differentInvoiceContact && (!value || value === '')) {
      return translateValidation('required')
    }
    return undefined
  }
  return (
    <Formik onSubmit={submit} initialValues={contact} validationSchema={contactSchema}>
      {({setFieldValue, errors, touched, submitForm}: FormikProps<Contact>) => {
        return (
          <ContentPanel>
            <Form>
              <Box>
                <FormRow
                  name="name"
                  label={t('name')}
                  disabled={isCompleted}
                  errors={errors}
                  touched={touched}
                  validationSchema={contactSchema}
                />
                <FormRow
                  name="email"
                  label={t('email')}
                  disabled
                  errors={errors}
                  touched={touched}
                  validationSchema={contactSchema}
                />
              </Box>
              <Box mt={3}>
                <Box pb={1}>
                  {t('alternativeInvoiceContact')}:&nbsp;
                  <input
                    type="checkbox"
                    checked={differentInvoiceContact}
                    disabled={isCompleted}
                    onChange={(e) => {
                      if (differentInvoiceContact) {
                        setFieldValue('company.invoiceContactName', '')
                        setFieldValue('company.invoiceContactEmail', '')
                      }
                      setDifferentInvoiceContact(!differentInvoiceContact)
                    }}
                  />
                </Box>
                {differentInvoiceContact && (
                  <Box pl={1}>
                    <FormRow
                      name="company.invoiceContactName"
                      label={t('name')}
                      disabled={isCompleted}
                      errors={errors}
                      touched={touched}
                      validationSchema={contactSchema}
                      required
                      validator={invoiceContactValidator}
                    />
                    <FormRow
                      name="company.invoiceContactEmail"
                      label={t('email')}
                      disabled={isCompleted}
                      errors={errors}
                      touched={touched}
                      validationSchema={contactSchema}
                      required
                      validator={invoiceContactValidator}
                    />
                  </Box>
                )}
              </Box>
              <Box mt={3}>
                <FormRow
                  name="company.name"
                  label={t('companyName')}
                  disabled={isCompleted}
                  errors={errors}
                  touched={touched}
                  validationSchema={contactSchema}
                />
                <FormRow
                  name="company.address"
                  label={t('address')}
                  disabled={isCompleted}
                  errors={errors}
                  touched={touched}
                  validationSchema={contactSchema}
                />
                <FormRow
                  name="company.postalCode"
                  label={t('postalCode')}
                  disabled={isCompleted}
                  errors={errors}
                  touched={touched}
                  validationSchema={contactSchema}
                />
                <FormRow
                  name="company.city"
                  label={t('city')}
                  disabled={isCompleted}
                  errors={errors}
                  touched={touched}
                  validationSchema={contactSchema}
                />
                <FormRow
                  name="company.country"
                  label={t('country')}
                  disabled={isCompleted}
                  errors={errors}
                  touched={touched}
                  validationSchema={contactSchema}
                />
                <FormRow
                  name="company.commercialRegNr"
                  label={t('companyCommercialRegNr')}
                  disabled={isCompleted}
                  errors={errors}
                  touched={touched}
                  validationSchema={contactSchema}
                />
                <FormRow
                  name="company.vatRegNr"
                  label={t('companyVatRegNr')}
                  disabled={isCompleted}
                  errors={errors}
                  touched={touched}
                  validationSchema={contactSchema}
                />
              </Box>
              <Box mt={3}>
                <FormRow
                  name="poNumber"
                  label={t('poNumber')}
                  disabled={isCompleted}
                  errors={errors}
                  touched={touched}
                  validationSchema={contactSchema}
                />
              </Box>
              <PagingBar
                previous={
                  <Link to={backLink}>
                    {t('backTo')} &raquo;{t('steps:products')}&laquo;{' '}
                  </Link>
                }
                next={
                  <button type="button" onClick={submitForm}>
                    {t('proceed')}
                  </button>
                }
              />
            </Form>
          </ContentPanel>
        )
      }}
    </Formik>
  )
}
