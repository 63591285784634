import {Box, Flex} from '@indoqa/style-system'
import {Product} from 'licensing-client-typescript/dist/index'
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import history from '../../app/history'
import {checkoutPathContactDetails} from '../checkout.paths'
import {ContentPanel} from '../components/ContentPanel'
import {PagingBar} from '../components/PagingBar'
import {ProductName} from '../components/ProductName'
import {TermsModalWindow} from '../components/TermsModalWindow'
import {CFProductsIntro} from '../content-fragments/CFProductsIntro'
import {CFProductsSelection} from '../content-fragments/CFProductsSelection'
import {useQueryParameter} from '../hooks/useQueryParameter'
import StepLayout from '../layouts/StepLayout'
import {loadOffer, selectProduct} from '../store/checkout.actions'
import {getErrorMessage, getOffer, getSelectedProducts, getTerms, isCheckoutCompleted} from '../store/checkout.selectors'

export const ProductsPage: React.FC = () => {
  const {t} = useTranslation(['forms', 'steps'])
  const dispatch = useDispatch()
  const offerId = useQueryParameter('l')
  const offer = useSelector(getOffer)
  const products = Object.values(offer?.products ?? {})
  const selectedProducts = useSelector(getSelectedProducts)
  const hasSelectedProducts = Object.keys(selectedProducts).length > 0
  const isCompleted = useSelector(isCheckoutCompleted)
  const errorMessage = useSelector(getErrorMessage)
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(null)

  const terms = useSelector(getTerms)

  React.useEffect(() => {
    if (!offer) {
      dispatch(loadOffer(offerId))
    }
  }, [dispatch, offerId, offer])

  if (errorMessage) {
    return (
      <Flex fullHeight fullWidth center>
        {t('steps:noProductsSelected')}
      </Flex>
    )
  }
  if (!offer) {
    return null
  }
  return (
    <StepLayout currentPosition={1}>
      <TermsModalWindow
        title={selectedProduct?.name}
        show={selectedProduct !== null}
        terms={terms}
        onClose={() => setSelectedProduct(null)}
      />

      <CFProductsIntro />
      <CFProductsSelection />
      <ContentPanel>
        {products.map((product) => {
          const selected = selectedProducts[product.id] !== undefined
          return (
            <Flex key={product.id} nowrap>
              <Box>
                <input
                  type="checkbox"
                  checked={selected}
                  disabled={isCompleted}
                  style={{marginTop: 4}}
                  onChange={(e) => dispatch(selectProduct(product, e.target.checked))}
                />
              </Box>
              <Box ml={2}>
                <ProductName product={product} selectProduct={setSelectedProduct} />
              </Box>
            </Flex>
          )
        })}
      </ContentPanel>
      <PagingBar
        next={
          <button disabled={!hasSelectedProducts} onClick={() => history.push(checkoutPathContactDetails())}>
            {t('proceed')}
          </button>
        }
      />
    </StepLayout>
  )
}
