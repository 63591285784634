import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router'
import {checkoutPathProducts} from '../checkout.paths'
import {ContentPanel} from '../components/ContentPanel'
import {ProductName} from '../components/ProductName'
import {CFDownloadIntro} from '../content-fragments/CFDownloadIntro'
import StepLayout from '../layouts/StepLayout'
import {getSelectedProducts, getValidatedCheckout} from '../store/checkout.selectors'

export const DownloadsPage: React.FC = () => {
  const {t} = useTranslation('forms')
  const validatedCheckout = useSelector(getValidatedCheckout)
  const selectedProducts = useSelector(getSelectedProducts)
  const multiple = Object.values(validatedCheckout?.productIds ?? {}).length > 1

  if (!validatedCheckout) {
    return <Redirect to={checkoutPathProducts()} />
  }

  return (
    <StepLayout currentPosition={4}>
      <CFDownloadIntro email={validatedCheckout?.contact?.email ?? ''} multiple={multiple} />
      <ContentPanel>
        <h3>{t('downloadLinks')}</h3>
        <ul>
          {Object.entries(validatedCheckout?.downloads).map((downloadEntry) => {
            const [id, download] = downloadEntry
            const product = selectedProducts[id]
            return (
              <li key={id}>
                <a href={download.link} key={product.id} target="_blank" rel="noopener noreferrer" download>
                  <ProductName product={product} />
                </a>
              </li>
            )
          })}
        </ul>
      </ContentPanel>
    </StepLayout>
  )
}
