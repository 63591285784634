import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {Language, useCurrentLanguage} from '../../commons/hooks/useCurrentLanguage'
import {ContentPanel} from '../components/ContentPanel'

interface Props {
  email: string
  multiple: boolean
}

export const CFDownloadIntro: React.FC<Props> = ({email, multiple}) => {
  const {t} = useTranslation('steps')
  const pluralS = multiple ? 's' : ''
  const lang = useCurrentLanguage()

  const headline = <h1>{t('download')}</h1>
  if (lang === Language.EN) {
    return (
      <ContentPanel>
        {headline}
        <p>
          Thank you for choosing Indoqa software. Below you will find the download link{pluralS} to the selected product
          {pluralS}.
        </p>
        <p>
          We will shortly send you an email to &raquo;{email}&laquo;, in which all information will be summarized.
        </p>
      </ContentPanel>
    )
  }
  return (
    <ContentPanel>
      {headline}
      <p>
        Vielen Dank, dass Sie sich für Indoqa Software entschieden haben.
        {multiple ? (
          <> Unten finden Sie die Download-Links zu den ausgewählten Produkten. </>
        ) : (
          <> Unten finden Sie den Download-Link zum ausgewählten Produkt. </>
        )}
      </p>
      <p>
        In Kürze senden wir Ihnen auch eine E-Mail an &raquo;{email}&laquo;, in der alle Informationen zusammengefasst
        werden.
      </p>
    </ContentPanel>
  )
}
