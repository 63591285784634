import {Contact, OfferType} from 'licensing-client-typescript'
import * as React from 'react'
import {useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import {checkoutPathProducts} from '../checkout.paths'
import {ContactDetailsDemoForm} from '../components/ContactDetailsDemoForm'
import {ContactDetailsProdForm} from '../components/ContactDetailsProdForm'
import {CFContactDetailsIntro} from '../content-fragments/CFContactDetailsIntro'
import StepLayout from '../layouts/StepLayout'
import {getContact, getOffer, getOfferId, getSelectedProducts} from '../store/checkout.selectors'

export const ContactDetailsPage: React.FC = () => {
  const selectedProducts = Object.values(useSelector(getSelectedProducts))
  const offerId = useSelector(getOfferId)
  const contact: Contact = useSelector(getContact)
  const prod = useSelector(getOffer)?.type === OfferType.PROD

  if (!selectedProducts || selectedProducts.length === 0) {
    return <Redirect to={checkoutPathProducts()} />
  }

  const backLink = checkoutPathProducts(offerId!)
  return (
    <StepLayout currentPosition={2}>
      <CFContactDetailsIntro multiple={selectedProducts.length > 1} />
      {prod ? (
        <ContactDetailsProdForm contact={contact} backLink={backLink} />
      ) : (
        <ContactDetailsDemoForm contact={contact} backLink={backLink} />
      )}
    </StepLayout>
  )
}
