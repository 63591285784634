import * as React from 'react'
import {Footer} from '../../commons-branded/footer/Footer'
import {Navbar} from '../../commons-branded/navbar/Navbar'
import {ContentLayout} from '../layouts/app-layout/AppLayout'

const Page: React.FC = ({children}) => {
  return <ContentLayout header={<Navbar />} footer={<Footer />}>{children}</ContentLayout>
}

export default Page
