import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n.use(LanguageDetector).init({
  resources: {
    en: {
      steps: {
        changeLanguage: 'Sprache wechseln',
        products: 'Software products',
        contact: 'Contact details',
        summary: 'Summary',
        download: 'Download',
        nextSteps: 'Next steps',
        noProductsSelected: 'There are no products available. Please contact »sales@indoqa.com«',
      },
      forms: {
        edit: 'Edit',
        cancel: 'Cancel',
        save: 'Save',
        addUser: 'Add User',
        name: 'Name',
        email: 'Email',
        companyName: 'Company name',
        address: 'Address',
        city: 'City',
        postalCode: 'Postal code',
        country: 'Country',
        companyCommercialRegNr: 'Register number',
        companyVatRegNr: 'VAT number',
        alternativeInvoiceContact: 'Alternative invoicing contact',
        invoiceContactName: "Invoicing contact",
        invoiceContactEmail: "Invoicing E-Mail",
        poNumber: "Your purchase order number",
        errorMissingEmail: "The field 'E-Mail' is required.",
        selectedProducts: 'Selected software products',
        selectedProduct: 'Selected software product',
        termsOfUse: 'Terms of use',
        terms: 'Terms of use',
        newsletter: 'Newsletter',
        downloadLinks: 'Download links',
        backTo: 'Back to',
        proceed: 'Proceed',
        proceedToDownload: 'Proceed to download',
        proceedToNextSteps: 'Proceed',
      },
      validation: {
        required: 'Required field',
        email: 'No valid email address',
      },
      others: {
        period_DAY: "{{count}} day",
        period_DAY_plural: "{{count}} days",
        period_YEAR: "{{count}} year",
        period_YEAR_plural: "{{count}} years",
        demoVersion: 'free demo version',
        for: 'for',
        validFor: 'valid for',
        at: 'at the price of',
        contact: 'Contact',
        legalNotice: 'Legal notice',
        privacyPolicy: 'Privacy policy',
      },
    },
    de: {
      steps: {
        changeLanguage: 'Change language',
        products: 'Software-Produkte',
        contact: 'Kontaktdaten',
        summary: 'Zusammenfassung',
        nextSteps: 'Nächste Schritte',
        noProductsSelected: 'Es stehen keine Software-Produkte zur Verfügung. Bitte kontaktieren Sie »sales@indoqa.com«',
      },
      forms: {
        edit: 'Bearbeiten',
        cancel: 'Abbrechen',
        save: 'Speichern',
        addUser: 'Benutzer hinzufügen',
        name: 'Name',
        email: 'E-Mail',
        companyName: 'Firmenname',
        address: 'Adresse',
        city: 'Ort',
        postalCode: 'Postleitzahl',
        country: 'Land',
        companyCommercialRegNr: 'Firmenbuchnummer',
        companyVatRegNr: 'UID-Nummer',
        alternativeInvoiceContact: 'Alternativer Rechnungsempfänger',
        invoiceContactName: 'Rechnungsempfänger - Name',
        invoiceContactEmail: 'Rechnungsempfänger - E-Mail',
        poNumber: 'Ihre Bestellnummer',
        errorMissingEmail: 'Es wird eine E-Mail-Adresse benötigt.',
        selectedProducts: 'Gewählte Software-Produkte',
        selectedProduct: 'Gewähltes Software-Produkt',
        termsOfUse: 'Softwareüberlassungsbedingungen',
        terms: 'Bedingungen',
        newsletter: 'Newsletter',
        downloadLinks: 'Download-Links',
        backTo: 'Zurück zu',
        proceed: 'Weiter',
        proceedToDownload: 'Weiter zum Download',
        proceedToNextSteps: 'Weiter',
      },
      validation: {
        required: 'Pflichtfeld',
        email: 'Das ist keine gültige E-Mail-Adresse',
      },
      others: {
        period_DAY: "{{count}} Tag",
        period_DAY_plural: "{{count}} Tage",
        period_YEAR: "{{count}} Jahr",
        period_YEAR_plural: "{{count}} Jahre",
        demoVersion: 'kostenlose Demo-Version',
        validFor: 'gültig für',
        for: 'für',
        at: 'zu einem Preis von',
        termsOfUse: 'Softwareüberlassungsbedingungen',
        contact: 'Kontakt',
        legalNotice: 'Impressum',
        privacyPolicy: 'Datenschutzerklärung',
      },
    },
  },
  fallbackLng: 'de',
  debug: true,

  // have a common namespace used around the full app
  ns: ['translations'],
  defaultNS: 'translations',

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
  },

  react: {
    wait: true,
  },
})

export default i18n
