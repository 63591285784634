import * as React from 'react'
import {Language, useCurrentLanguage} from '../../commons/hooks/useCurrentLanguage'
import {ContentPanel} from '../components/ContentPanel'

interface Props {
  multiple: boolean
}

export const CFContactDetailsIntro: React.FC<Props> = ({multiple}) => {
  const lang = useCurrentLanguage()
  if (lang === Language.EN) {
    return (
      <ContentPanel>
        <h1>Contact details</h1>
        <p>
          Next, we need some information from you:
        </p>
      </ContentPanel>
    )
  }
  return (
    <ContentPanel>
      <h1>Kontaktdaten</h1>
      <p>
        Als nächstes benötigen wir einige Informationen von Ihnen:
      </p>
    </ContentPanel>
  )
}
