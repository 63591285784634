"use strict";
// tslint:disable
/**
 * Indoqa Licensing
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
 *
 * @export
 * @enum {string}
 */
var OfferType;
(function (OfferType) {
    OfferType["DEMO"] = "DEMO";
    OfferType["PROD"] = "PROD";
})(OfferType = exports.OfferType || (exports.OfferType = {}));
