import {Box, Flex} from '@indoqa/style-system'
import {OfferType, Product} from 'licensing-client-typescript'
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import {LinkButton} from '../../commons/components/LinkButton'
import {useCurrentLanguage} from '../../commons/hooks/useCurrentLanguage'
import {checkoutPathContactDetails, checkoutPathProducts} from '../checkout.paths'
import {ContentPanel} from '../components/ContentPanel'
import {PagingBar} from '../components/PagingBar'
import {ProductName} from '../components/ProductName'
import {SummaryTableData} from '../components/SummaryTableData'
import {TermsModalWindow} from '../components/TermsModalWindow'
import {CFAcceptNewsletter} from '../content-fragments/CFAcceptNewsletter'
import {CFAcceptTermsOfUser} from '../content-fragments/CFAcceptTermsOfUse'
import {CFSummaryIntro} from '../content-fragments/CFSummaryIntro'
import StepLayout from '../layouts/StepLayout'
import {createCheckout, loadTerms, setNewsletterAccepted, setTermsAccepted} from '../store/checkout.actions'
import {
  getContact,
  getOffer,
  getSelectedProducts,
  getTerms,
  isCheckoutCompleted,
  isNewsletterAccepted,
  isTermsAccepted,
} from '../store/checkout.selectors'

export const SummaryPage: React.FC = () => {
  const {t} = useTranslation(['forms', 'steps'])
  const dispatch = useDispatch()
  const selectedProducts = Object.values(useSelector(getSelectedProducts))
  const type = useSelector(getOffer)?.type
  const contact = useSelector(getContact)
  const terms = useSelector(getTerms)
  const termsAccepted = useSelector(isTermsAccepted)
  const newsletterAccepted = useSelector(isNewsletterAccepted)
  const lang = useCurrentLanguage()
  const isCompleted = useSelector(isCheckoutCompleted)
  const [selectedProduct, setSelectedProduct] = React.useState<Product | null>(null)

  if (selectedProducts.length === 0 || !contact) {
    return <Redirect to={checkoutPathProducts()} />
  }

  const nextButtonText = type === OfferType.DEMO ? t('proceedToDownload') : t('proceedToNextSteps')

  return (
    <StepLayout currentPosition={3}>
      <TermsModalWindow
        title={selectedProduct?.name}
        show={selectedProduct !== null}
        terms={terms}
        onClose={() => setSelectedProduct(null)}
      />

      <CFSummaryIntro />

      <ContentPanel>
        <h3>{selectedProducts.length > 1 ? t('selectedProducts') : t('selectedProduct')}</h3>
        <ul>
          {selectedProducts.map((product) => (
            <li key={product.id}>
              <ProductName product={product} selectProduct={setSelectedProduct} />
            </li>
          ))}
        </ul>
      </ContentPanel>

      <ContentPanel>
        <h3>{t('steps:contact')}</h3>
        <table>
          <tbody>
          <tr>
            <SummaryTableData>{t('name')}:</SummaryTableData>
            <SummaryTableData> {contact.name}</SummaryTableData>
          </tr>
          <tr>
            <SummaryTableData>{t('email')}:</SummaryTableData>
            <SummaryTableData> {contact.email}</SummaryTableData>
          </tr>
          {type === OfferType.PROD && contact.company && (
            <>
              {contact.company.invoiceContactName && (
                <>
                  <tr>
                    <SummaryTableData>{t('invoiceContactName')}:</SummaryTableData>
                    <SummaryTableData>{contact.company.invoiceContactName}</SummaryTableData>
                  </tr>
                  <tr>
                    <SummaryTableData>{t('invoiceContactEmail')}:</SummaryTableData>
                    <SummaryTableData>{contact.company.invoiceContactEmail}</SummaryTableData>
                  </tr>
                </>
              )}
              <tr>
                <SummaryTableData>{t('companyName')}:</SummaryTableData>
                <SummaryTableData>{contact.company.name}</SummaryTableData>
              </tr>
              <tr>
                <SummaryTableData>{t('address')}:</SummaryTableData>
                <SummaryTableData>{contact.company.address}</SummaryTableData>
              </tr>
              <tr>
                <SummaryTableData>{t('postalCode')}:</SummaryTableData>
                <SummaryTableData>{contact.company.postalCode}</SummaryTableData>
              </tr>
              <tr>
                <SummaryTableData>{t('city')}:</SummaryTableData>
                <SummaryTableData>{contact.company.city}</SummaryTableData>
              </tr>
              <tr>
                <SummaryTableData>{t('country')}:</SummaryTableData>
                <SummaryTableData>{contact.company.country}</SummaryTableData>
              </tr>
              <tr>
                <SummaryTableData>{t('companyCommercialRegNr')}:</SummaryTableData>
                <SummaryTableData>{contact.company.commercialRegNr}</SummaryTableData>
              </tr>
              <tr>
                <SummaryTableData>{t('companyVatRegNr')}:</SummaryTableData>
                <SummaryTableData>{contact.company.vatRegNr}</SummaryTableData>
              </tr>
              <tr>
                <SummaryTableData>{t('poNumber')}:</SummaryTableData>
                <SummaryTableData>{contact.poNumber}</SummaryTableData>
              </tr>
            </>
          )}
          </tbody>
        </table>
      </ContentPanel>

      <ContentPanel>
        <h3>{t('termsOfUse')}</h3>
        <Flex nowrap>
          <Box pr={1}>
            <input
              type="checkbox"
              checked={termsAccepted}
              disabled={isCompleted}
              onChange={(e) => dispatch(setTermsAccepted(e.target.checked))}
            />
          </Box>
          <Box>
            <CFAcceptTermsOfUser />
            <ul>
              {selectedProducts.map((product) => (
                <li key={product.id}>
                  <LinkButton
                    onClick={() => {
                      dispatch(loadTerms(product.id, lang))
                      setSelectedProduct(product)
                    }}
                  >
                    {t('terms')} &raquo;{product.name}&laquo;
                  </LinkButton>
                </li>
              ))}
            </ul>
          </Box>
        </Flex>
      </ContentPanel>

      <ContentPanel>
        <h3>{t('newsletter')}</h3>
        <Flex nowrap>
          <Box pr={1}>
            <input
              type="checkbox"
              checked={newsletterAccepted}
              disabled={isCompleted}
              onChange={(e) => dispatch(setNewsletterAccepted(e.target.checked))}
            />
          </Box>
          <Box>
            <CFAcceptNewsletter />
          </Box>
        </Flex>
      </ContentPanel>

      <PagingBar
        previous={
          <Link to={checkoutPathContactDetails()}>
            {t('backTo')} &raquo;{t('steps:contact')}&laquo;
          </Link>
        }
        next={
          <button disabled={!termsAccepted} onClick={() => dispatch(createCheckout())}>
            {nextButtonText}
          </button>
        }
      />
    </StepLayout>
  )
}
