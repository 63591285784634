import {IStyle} from 'fela'
import * as React from 'react'
import {useFela} from 'react-fela'
import {Theme} from '../../app/theme'

export const SummaryTableData: React.FC = ({children}) => {
  const {css, theme} = useFela<Theme>()
  const style: IStyle = {
    paddingBottom: theme.spacing.space1,
    paddingRight: theme.spacing.space1,
  }
  return (
    <td className={css(style)}>{children}</td>
  )
}
