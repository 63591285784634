const BASE_PATH = '/checkout'

export const checkoutQueryParams = {
  productList: 'l'
}

export const checkoutPathProducts = (productListName?: string) => {
  if (!productListName) {
    return `${BASE_PATH}/products`
  }
  return `${BASE_PATH}/products?${checkoutQueryParams.productList}=${productListName}`
}

export const checkoutPathContactDetails = () => `${BASE_PATH}/contact-details`

export const checkoutPathSummary = () => `${BASE_PATH}/summary`

export const checkoutPathDownloads = () => `${BASE_PATH}/download`

export const checkoutPathNextSteps = () => `${BASE_PATH}/next-steps`
