import {createSelector} from 'reselect'

import {selectCheckoutState} from '../../app/selectors'
import {CheckoutState} from './checkout.types'

export const getOffer = createSelector(selectCheckoutState, (state: CheckoutState) => state.offer)
export const getOfferId = createSelector(selectCheckoutState, (state: CheckoutState) => state.offerId)
export const getSelectedProducts = createSelector(selectCheckoutState, (state: CheckoutState) => state.selectedProducts)
export const getContact = createSelector(selectCheckoutState, (state: CheckoutState) => state.contact)
export const getValidatedCheckout = createSelector(selectCheckoutState, (state: CheckoutState) => state.validatedCheckout)
export const isCheckoutCompleted = createSelector(selectCheckoutState, (state: CheckoutState) => state.validatedCheckout !== null)
export const isTermsAccepted = createSelector(selectCheckoutState, (state: CheckoutState) => state.termsAccepted)
export const isNewsletterAccepted = createSelector(selectCheckoutState, (state: CheckoutState) => state.newsletterAccepted)
export const getErrorMessage = createSelector(selectCheckoutState, (state: CheckoutState) => state.errorMessage)
export const getTerms = createSelector(selectCheckoutState, (state: CheckoutState) => state.terms)
