import * as React from 'react'
import {useSelector} from 'react-redux'
import {CFNextStepsIntro} from '../content-fragments/CFNextStepsIntro'
import StepLayout from '../layouts/StepLayout'
import {getValidatedCheckout} from '../store/checkout.selectors'

interface Props {
  email: string
}

export const NextStepsPage: React.FC<Props> = ({email}) => {
  const validatedCheckout = useSelector(getValidatedCheckout)
  const multiple = Object.values(validatedCheckout?.productIds ?? {}).length > 1
  return (
    <StepLayout currentPosition={4}>
      <CFNextStepsIntro multiple={multiple} />
    </StepLayout>
  )
}
