import * as React from 'react'
import {Language, useCurrentLanguage} from '../../commons/hooks/useCurrentLanguage'

export const CFAcceptNewsletter: React.FC = () => {
  const lang = useCurrentLanguage()
  if (lang === Language.EN) {
    return (
      <p>
        You agree that we may provide you with regular information on Indoqa software products
        by email. You can revoke your consent to Indoqa at any time.
      </p>
    )
  }
  return (
    <p>
      Sie erklären sich damit einverstanden, dass wir Ihnen regelmäßig Informationen zu Indoqa Software-Produkten
      per E-Mail zuschicken. Ihre Einwilligung können Sie jederzeit gegenüber Indoqa widerrufen.
    </p>
  )
}
