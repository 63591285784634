import {Box, Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import {OfferType} from 'licensing-client-typescript'
import * as React from 'react'
import {PropsWithChildren} from 'react'
import {useTranslation} from 'react-i18next'
import {useSelector} from 'react-redux'
import Page from '../../commons/components/Page'
import Step from '../components/Step'
import {getOffer} from '../store/checkout.selectors'

export interface Props {
  currentPosition: number
}

const StepLayout = ({currentPosition, children}: PropsWithChildren<Props>) => {
  const {t} = useTranslation('steps')
  const type = useSelector(getOffer)?.type
  const contentStyle: IStyle = {
    overflow: 'auto',
  }
  return (
    <Page>
      <Flex maxWidth={1200} fullWidth nowrap>
        <Flex py={3} fullHeight direction="column" justifyContent="space-between" minHeight={500} width={250} grow={0}>
          <Box px={2}>
            <Step position={1} label={t('products')} selected={currentPosition === 1} />
            <Step position={2} label={t('contact')} selected={currentPosition === 2} />
            <Step position={3} label={t('summary')} selected={currentPosition === 3} />
            {type === OfferType.DEMO ? (
              <Step position={4} label={t('download')} selected={currentPosition === 4} />
            ) : (
              <Step position={4} label={t('nextSteps')} selected={currentPosition === 4} />
            )}
          </Box>
        </Flex>
        <Box grow={1} fullHeight style={contentStyle} p={3}>
          {children}
        </Box>
      </Flex>
    </Page>
  )
}

export default StepLayout
