import {IRenderer} from 'fela'

const cooperFiles = [
  '/fonts/CooperBTW03-Light.woff2',
  '/fonts/CooperBTW03-Light.woff',
  '/fonts/CooperBTW03-Light.ttf',
  '/fonts/CooperBTW03-Light.eot',
]

const graphikLightFiles = [
  '/fonts/Graphik-Light-Web.woff2',
  '/fonts/Graphik-Light-Web.woff',
  '/fonts/Graphik-Light-Web.ttf',
  '/fonts/Graphik-Light-Web.eot',
]

const graphikMediumFiles = [
  '/fonts/Graphik-Medium-Web.woff2',
  '/fonts/Graphik-Medium-Web.woff',
  '/fonts/Graphik-Medium-Web.ttf',
  '/fonts/Graphik-Medium-Web.eot',
]

const graphikRegularFiles = [
  '/fonts/Graphik-Regular-Web.woff2',
  '/fonts/Graphik-Regular-Web.woff',
  '/fonts/Graphik-Regular-Web.ttf',
  '/fonts/Graphik-Regular-Web.eot',
]

export const loadFonts = (renderer: IRenderer) => {
  renderer.renderFont('Cooper', cooperFiles, {fontWeight: 400})
  renderer.renderFont('Graphik', graphikMediumFiles, {fontWeight: 500})
  renderer.renderFont('Graphik', graphikRegularFiles, {fontWeight: 400})
  renderer.renderFont('Graphik', graphikLightFiles, {fontWeight: 300})
}
