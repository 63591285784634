import {Flex} from '@indoqa/style-system'
import {IStyle} from 'fela'
import * as React from 'react'
import {FelaComponent} from 'react-fela'

export interface Props {
  position: number
  label: string
  selected?: boolean
}

const Step = ({position, label, selected}: Props) => {
  const positionStyle: IStyle = {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50',
    background: selected ? '#000' : '#ebebeb',
    color: selected ? '#fff' : '#777',
    marginRight: 10,
    border: '5px solid #fff',
    zIndex: 1,
  }

  const labelStyle: IStyle = {
    color: selected ? '#000' : '#777',
  }

  return (
    <Flex alignItems={'center'} mb={2}>
      <FelaComponent style={positionStyle}>{position}</FelaComponent>
      <FelaComponent style={labelStyle}>{label}</FelaComponent>
    </Flex>
  )
}

export default Step
