import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {Language, useCurrentLanguage} from '../../commons/hooks/useCurrentLanguage'
import {ContentPanel} from '../components/ContentPanel'

export const CFSummaryIntro: React.FC = () => {
  const {t} = useTranslation('steps')
  const lang = useCurrentLanguage()

  const headline = <h1>{t('summary')}</h1>

  if (lang === Language.EN) {
    return (
      <ContentPanel>
        {headline}
        <p>
          We ask you to check your information here again. With &raquo;Back&laquo; you can make changes:
        </p>
      </ContentPanel>
    )
  }

  return (
    <ContentPanel>
      {headline}
      <p>
        Wir bitten Sie Ihre Angaben hier nochmals zu prüfen. Gegebenenfalls können Sie mit &raquo;Zurück&laquo; Ihre
        Angaben ändern:
      </p>
    </ContentPanel>
  )
}
