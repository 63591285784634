import produce from 'immer'
import {CheckoutAction, CheckoutActionKeys, CheckoutState} from './checkout.types'

const initialState: CheckoutState = {
  offerId: null,
  offer: null,
  selectedProducts: {},
  contact: {
    name: '',
    email: '',
    company: {
      name: '',
      address: '',
      postalCode: '',
      city: '',
      commercialRegNr: '',
      country: '',
      vatRegNr: '',
      invoiceContactName: '',
      invoiceContactEmail: '',
    },
    poNumber: '',
  },
  termsAccepted: false,
  newsletterAccepted: false,
  validatedCheckout: null,
  errorMessage: null,
  terms: null,
  termsErrorMessage: null,
}

const checkoutReducer = (state: CheckoutState = initialState, action: CheckoutAction) => {
  return produce(state, (draft: CheckoutState) => {
    switch (action.type) {
      case CheckoutActionKeys.LOAD_OFFER: {
        draft = initialState
        return
      }
      case CheckoutActionKeys.LOAD_OFFER_SUCCESS: {
        draft.offerId = action.offerId
        draft.offer = action.offer
        draft.selectedProducts = action.offer.products ?? {}
        draft.contact.email = action.offer.userEmail
        return
      }
      case CheckoutActionKeys.LOAD_OFFER_ERROR: {
        draft.errorMessage = action.error.message
        return
      }
      case CheckoutActionKeys.SELECT_PRODUCT: {
        const product = action.product
        if (action.selected) {
          draft.selectedProducts[product.id] = product
        } else {
          delete draft.selectedProducts[product.id]
        }
        return
      }
      case CheckoutActionKeys.SET_TERMS_ACCEPTED: {
        draft.termsAccepted = action.accepted
        return
      }
      case CheckoutActionKeys.SET_NEWSLETTER_ACCEPTED: {
        draft.newsletterAccepted = action.accepted
        return
      }
      case CheckoutActionKeys.STORE_USER_DATA: {
        draft.contact = action.contact
        return
      }
      case CheckoutActionKeys.CREATE_CHECKOUT_SUCCESS: {
        draft.validatedCheckout = action.validatedCheckout
        return
      }
      case CheckoutActionKeys.LOAD_TERMS: {
        draft.terms = null
        draft.termsErrorMessage = null
        return
      }
      case CheckoutActionKeys.LOAD_TERMS_SUCCESS: {
        draft.terms = action.terms
        draft.termsErrorMessage = null
        return
      }
      case CheckoutActionKeys.LOAD_TERMS_ERROR: {
        draft.terms = null
        draft.termsErrorMessage = action.error.message
        return
      }
    }
  })
}

export default checkoutReducer
