import {Box, Flex} from '@indoqa/style-system'
import * as React from 'react'
import {ModalWindow} from '../../commons/components/ModalWindow'
import {TermsText} from './TermsText'

interface Props {
  title?: string,
  show: boolean,
  terms: string | null
  onClose: () => void
}

export const TermsModalWindow = ({title, show, terms, onClose}: Props) => {
  const modalWindowHeader = <Box px={1}>{title}</Box>
  const modalWindowFooter = (
    <Flex justifyContent="flex-end" fullWidth px={1}>
      <button onClick={onClose}>Schließen</button>
    </Flex>
  )
  return (
    <ModalWindow
      show={show}
      onRequestClose={onClose}
      header={modalWindowHeader}
      footer={modalWindowFooter}
    >
      <Box fullWidth p={1}>
        {terms && <TermsText text={terms} />}
      </Box>
    </ModalWindow>
  )
}
