import {OfferType} from 'licensing-client-typescript'
import * as React from 'react'
import {useSelector} from 'react-redux'
import {Language, useCurrentLanguage} from '../../commons/hooks/useCurrentLanguage'
import {ContentPanel} from '../components/ContentPanel'
import {getOffer} from '../store/checkout.selectors'

const renderDemo = (lang: Language) => {
  if (lang === Language.EN) {
    return (
      <ContentPanel>
        <h1>Download Indoqa demo software</h1>
        <p>
          Thank you for your interest in downloading Indoqa demo software! On the next few pages we kindly ask you to
          provide us with some information. Please note that our terms and conditions are currently only available in
          German.
        </p>
        <p>Finally, you will receive a link to download the selected software.</p>
        <p>
          With the download you guarantee to be an entrepreneur and not a consumer in the sense of the consumer
          protection law.
        </p>
      </ContentPanel>
    )
  }
  return (
    <ContentPanel>
      <h1>Indoqa Demo-Software herunterladen</h1>
      <p>
        Vielen Dank für Ihr Interesse daran, Indoqa Demo-Software herunterzuladen. Wir ersuchen Sie, uns dafür auf den
        folgenden Seiten einige Informationen zur Verfügung zu stellen.
      </p>
      <p>
        Abschließend erhalten Sie einen Link, um die ausgewählte Software downloaden zu können, wobei dieser Prozess
        ausschließlich in deutscher Sprache zur Verfügung steht.
      </p>
      <p>
        Mit dem Download garantieren Sie, Unternehmer und nicht Verbraucher im Sinne des Verbraucherschutzrechts zu
        sein.
      </p>
    </ContentPanel>
  )
}

const renderProd = (lang: Language) => {
  if (lang === Language.EN) {
    return (
      <ContentPanel>
        <h1>Licensing Indoqa Software</h1>
        <p>
          Thank you for your interest in licensing Indoqa Software products! On the next few pages we kindly ask you to
          provide us with some information. Please note that our terms and conditions are currently only available in
          German.
        </p>
        <p>
          Based on this information we will prepare an invoice and send it to you. As soon as we have received your
          payment, you will get the download links from us.
        </p>
        <p>
          With the download you guarantee to be an entrepreneur and not a consumer in the sense of the consumer
          protection law.
        </p>
      </ContentPanel>
    )
  }
  return (
    <ContentPanel>
      <h1>Indoqa Software erwerben</h1>
      <p>
        Vielen Dank für Ihr Interesse Indoqa Software zu erwerben. Wir ersuchen Sie, uns dafür auf den folgenden Seiten
        einige Informationen zur Verfügung zu stellen.
      </p>
      <p>
        Auf Basis dieser Informationen erstellen wir eine Rechnung und lassen Ihnen diese zukommen. Sobald die Zahlung
        bei uns eingelangt ist, erhalten Sie die Download-Links zur Software.
      </p>
      <p>
        Mit dem Download garantieren Sie, Unternehmer und nicht Verbraucher im Sinne des Verbraucherschutzrechts zu
        sein.
      </p>
    </ContentPanel>
  )
}

export const CFProductsIntro: React.FC = () => {
  const lang = useCurrentLanguage()
  const type = useSelector(getOffer)?.type
  switch (type) {
    case OfferType.DEMO:
      return renderDemo(lang)
    case OfferType.PROD:
      return renderProd(lang)
    default:
      return null
  }
}
