import * as React from 'react'
import {Language, useCurrentLanguage} from '../../commons/hooks/useCurrentLanguage'
import {ContentPanel} from '../components/ContentPanel'

interface Props {
  multiple: boolean
}

export const CFNextStepsIntro = ({multiple}: Props) => {
  const lang = useCurrentLanguage()
  const pluralS = multiple ? 's' : ''
  if (lang === Language.EN) {
    return (
      <ContentPanel>
        <h1>Thank you!</h1>
        <p>Thank you for choosing Indoqa software.</p>
        <p>Based on your information we will create an invoice and send it to you within the next few working days.</p>
        <p>As soon as we receive your payment, you will get the download link{pluralS} to the software.</p>
        <p>
          In the meantime, if you have any questions, please contact &raquo;sales@indoqa.com&laquo;. We will be glad to
          help you!
        </p>
      </ContentPanel>
    )
  }
  return (
    <ContentPanel>
      <h1>Vielen Dank!</h1>
      <p>Vielen Dank, dass Sie sich für Indoqa Software entschieden haben.</p>
      <p>
        Auf Basis Ihrer Informationen erstellen wir eine Rechnung und lassen Ihnen diese innerhalb der nächsten
        Werktagen zukommen.
      </p>
      <p>
        {multiple ? (
          <> Sobald Ihre Zahlung bei uns einlangt, erhalten Sie die Download-Links zur Software. </>
        ) : (
          <> Sobald Ihre Zahlung bei uns einlangt, erhalten Sie den Download-Link zur Software. </>
        )}
      </p>
      <p>
        Wenn Sie in der Zwischenzeit Fragen haben, wenden Sie sich bitte an &raquo;sales@indoqa.com&laquo;. Wir helfen
        Ihnen gerne weiter!
      </p>
    </ContentPanel>
  )
}
