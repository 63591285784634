import {Contact, Offer, Product, ValidatedCheckout} from 'licensing-client-typescript'
import {Action} from 'redux'

import {CheckoutActionKeys} from './checkout.types'

export interface SelectProducts extends Action {
  type: CheckoutActionKeys.SELECT_PRODUCT
  product: Product
  selected: boolean
}

export const selectProduct = (product: Product, selected: boolean): SelectProducts => ({
  type: CheckoutActionKeys.SELECT_PRODUCT,
  product,
  selected
})

export interface LoadOffer extends Action {
  type: CheckoutActionKeys.LOAD_OFFER
  offerId?: string | null
}

export const loadOffer = (offerId?: string | null): LoadOffer => ({
  type: CheckoutActionKeys.LOAD_OFFER,
  offerId,
})

export interface LoadOfferSuccess extends Action {
  type: CheckoutActionKeys.LOAD_OFFER_SUCCESS
  offerId: string,
  offer: Offer
}

export const loadOfferSuccess = (offerId: string, offer: Offer): LoadOfferSuccess => ({
  type: CheckoutActionKeys.LOAD_OFFER_SUCCESS,
  offerId,
  offer,
})

export interface LoadOfferError extends Action {
  type: CheckoutActionKeys.LOAD_OFFER_ERROR
  error: Error
}

export const loadOfferError = (error: Error): LoadOfferError => ({
  type: CheckoutActionKeys.LOAD_OFFER_ERROR,
  error,
})

export interface StoreContactDetails extends Action {
  type: CheckoutActionKeys.STORE_USER_DATA
  contact: Contact
}

export const storeContactDetails = (contact: Contact): StoreContactDetails => ({
  type: CheckoutActionKeys.STORE_USER_DATA,
  contact,
})

export interface SetNewsletterAccepted {
  type: CheckoutActionKeys.SET_NEWSLETTER_ACCEPTED,
  accepted: boolean
}

export const setNewsletterAccepted = (accepted: boolean): SetNewsletterAccepted => ({
  type: CheckoutActionKeys.SET_NEWSLETTER_ACCEPTED,
  accepted
})

export interface SetTermsAccepted {
  type: CheckoutActionKeys.SET_TERMS_ACCEPTED,
  accepted: boolean
}

export const setTermsAccepted = (accepted: boolean): SetTermsAccepted => ({
  type: CheckoutActionKeys.SET_TERMS_ACCEPTED,
  accepted
})

export interface CreateCheckout extends Action {
  type: CheckoutActionKeys.CREATE_CHECKOUT
}

export const createCheckout = (): CreateCheckout => ({
  type: CheckoutActionKeys.CREATE_CHECKOUT,
})

export interface CreateCheckoutSuccess extends Action {
  type: CheckoutActionKeys.CREATE_CHECKOUT_SUCCESS
  validatedCheckout: ValidatedCheckout
}

export const createCheckoutSuccess = (checkout: ValidatedCheckout): CreateCheckoutSuccess => ({
  type: CheckoutActionKeys.CREATE_CHECKOUT_SUCCESS,
  validatedCheckout: checkout,
})

export interface CreateCheckoutError extends Action {
  type: CheckoutActionKeys.CREATE_CHECKOUT_ERROR
  error: Error
}

export const createCheckoutError = (error: Error): CreateCheckoutError => ({
  type: CheckoutActionKeys.CREATE_CHECKOUT_ERROR,
  error,
})

export interface BuySoftware extends Action {
  type: CheckoutActionKeys.BUY_SOFTWARE
}

export const buySoftware = (): BuySoftware => ({
  type: CheckoutActionKeys.BUY_SOFTWARE,
})

export interface LoadTerms extends Action {
  type: CheckoutActionKeys.LOAD_TERMS
  productId: string,
  lang: string,
}

export const loadTerms = (productId: string, lang: string): LoadTerms => ({
  type: CheckoutActionKeys.LOAD_TERMS,
  productId,
  lang,
})

export interface LoadTermsSuccess extends Action {
  type: CheckoutActionKeys.LOAD_TERMS_SUCCESS,
  terms: string,
}

export const loadTermsSuccess = (terms: string): LoadTermsSuccess => ({
  type: CheckoutActionKeys.LOAD_TERMS_SUCCESS,
  terms,
})

export interface LoadTermsError extends Action {
  type: CheckoutActionKeys.LOAD_TERMS_ERROR,
  error: Error
}

export const loadTermsError = (error: Error): LoadTermsError => ({
  type: CheckoutActionKeys.LOAD_TERMS_ERROR,
  error
})
