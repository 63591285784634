"use strict";
// tslint:disable
/**
 * Indoqa Licensing
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var ajax_1 = require("rxjs/ajax");
var operators_1 = require("rxjs/operators");
exports.BASE_PATH = 'http://localhost'.replace(/\/+$/, '');
var Configuration = /** @class */ (function () {
    function Configuration(configuration) {
        if (configuration === void 0) { configuration = {}; }
        this.configuration = configuration;
    }
    Object.defineProperty(Configuration.prototype, "basePath", {
        get: function () {
            return this.configuration.basePath || exports.BASE_PATH;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Configuration.prototype, "middleware", {
        get: function () {
            return this.configuration.middleware || [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Configuration.prototype, "username", {
        get: function () {
            return this.configuration.username;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Configuration.prototype, "password", {
        get: function () {
            return this.configuration.password;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Configuration.prototype, "apiKey", {
        get: function () {
            var apiKey = this.configuration.apiKey;
            if (!apiKey) {
                return undefined;
            }
            return typeof apiKey === 'string' ? function () { return apiKey; } : apiKey;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Configuration.prototype, "accessToken", {
        get: function () {
            var accessToken = this.configuration.accessToken;
            if (!accessToken) {
                return undefined;
            }
            return typeof accessToken === 'string' ? function () { return accessToken; } : accessToken;
        },
        enumerable: true,
        configurable: true
    });
    return Configuration;
}());
exports.Configuration = Configuration;
/**
 * This is the base class for all generated API classes.
 */
var BaseAPI = /** @class */ (function () {
    function BaseAPI(configuration) {
        var _this = this;
        if (configuration === void 0) { configuration = new Configuration(); }
        this.configuration = configuration;
        this.middleware = [];
        this.withMiddleware = function (middlewares) {
            var next = _this.clone();
            next.middleware = next.middleware.concat(middlewares);
            return next;
        };
        this.withPreMiddleware = function (preMiddlewares) {
            return _this.withMiddleware(preMiddlewares.map(function (pre) { return ({ pre: pre }); }));
        };
        this.withPostMiddleware = function (postMiddlewares) {
            return _this.withMiddleware(postMiddlewares.map(function (post) { return ({ post: post }); }));
        };
        this.request = function (requestOpts) {
            return _this.rxjsRequest(_this.createRequestArgs(requestOpts)).pipe(operators_1.map(function (res) {
                if (res.status >= 200 && res.status < 300) {
                    return res.response;
                }
                throw res;
            }));
        };
        this.createRequestArgs = function (requestOpts) {
            var url = _this.configuration.basePath + requestOpts.path;
            if (requestOpts.query !== undefined && Object.keys(requestOpts.query).length !== 0) {
                // only add the queryString to the URL if there are query parameters.
                // this is done to avoid urls ending with a '?' character which buggy webservers
                // do not handle correctly sometimes.
                url += '?' + queryString(requestOpts.query);
            }
            return {
                url: url,
                method: requestOpts.method,
                headers: requestOpts.headers,
                body: requestOpts.body instanceof FormData ? requestOpts.body : JSON.stringify(requestOpts.body),
                responseType: requestOpts.responseType || 'json',
            };
        };
        this.rxjsRequest = function (params) {
            return rxjs_1.of(params).pipe(operators_1.map(function (request) {
                _this.middleware.filter(function (item) { return item.pre; }).forEach(function (mw) { return (request = mw.pre(request)); });
                return request;
            }), operators_1.concatMap(function (args) {
                return ajax_1.ajax(args).pipe(operators_1.map(function (response) {
                    _this.middleware.filter(function (item) { return item.post; }).forEach(function (mw) { return (response = mw.post(response)); });
                    return response;
                }));
            }));
        };
        /**
         * Create a shallow clone of `this` by constructing a new instance
         * and then shallow cloning data members.
         */
        this.clone = function () {
            return Object.assign(Object.create(Object.getPrototypeOf(_this)), _this);
        };
        this.middleware = configuration.middleware;
    }
    return BaseAPI;
}());
exports.BaseAPI = BaseAPI;
/**
 * @deprecated
 * export for not being a breaking change
 */
var RequiredError = /** @class */ (function (_super) {
    __extends(RequiredError, _super);
    function RequiredError() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.name = 'RequiredError';
        return _this;
    }
    return RequiredError;
}(Error));
exports.RequiredError = RequiredError;
exports.COLLECTION_FORMATS = {
    csv: ',',
    ssv: ' ',
    tsv: '\t',
    pipes: '|',
};
exports.encodeURI = function (value) { return encodeURIComponent(String(value)); };
var queryString = function (params) { return Object.keys(params)
    .map(function (key) {
    var value = params[key];
    return (value instanceof Array)
        ? value.map(function (val) { return exports.encodeURI(key) + "=" + exports.encodeURI(val); }).join('&')
        : exports.encodeURI(key) + "=" + exports.encodeURI(value);
})
    .join('&'); };
// alias fallback for not being a breaking change
exports.querystring = queryString;
/**
 * @deprecated
 */
exports.throwIfRequired = function (params, key, nickname) {
    if (!params || params[key] == null) {
        throw new RequiredError("Required parameter " + key + " was null or undefined when calling " + nickname + ".");
    }
};
exports.throwIfNullOrUndefined = function (value, nickname) {
    if (value == null) {
        throw new Error("Parameter \"" + value + "\" was null or undefined when calling \"" + nickname + "\".");
    }
};
