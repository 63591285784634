import {OfferType} from 'licensing-client-typescript'
import {Epic, ofType} from 'redux-observable'
import {of} from 'rxjs'
import {catchError, ignoreElements, map, switchMap, tap} from 'rxjs/operators'
import {LicensingApi} from '../../apis'
import history from '../../app/history'
import i18n from '../../app/i18n'

import {RootAction, RootState, Services} from '../../app/types'
import {checkoutPathDownloads, checkoutPathNextSteps, checkoutPathSummary} from '../checkout.paths'
import {
  CreateCheckout,
  createCheckoutError,
  createCheckoutSuccess,
  CreateCheckoutSuccess,
  LoadOffer,
  loadOfferError,
  loadOfferSuccess,
  LoadTerms,
  loadTermsError,
  loadTermsSuccess,
  StoreContactDetails,
} from './checkout.actions'
import {
  getContact,
  getOffer,
  getOfferId,
  getSelectedProducts,
  getValidatedCheckout,
  isNewsletterAccepted,
  isTermsAccepted,
} from './checkout.selectors'
import {CheckoutAction, CheckoutActionKeys} from './checkout.types'

interface CheckoutEpic extends Epic<RootAction, CheckoutAction, RootState, Services> {
}

const loadProductsEpic$: CheckoutEpic = (action$) =>
  action$.pipe(
    ofType<LoadOffer>(CheckoutActionKeys.LOAD_OFFER),
    switchMap((action) => {
      const {offerId} = action
      if (!offerId) {
        return of(loadOfferError(new Error('Missing offer')))
      }
      return LicensingApi.getOffer({id: offerId}).pipe(
        map((offer) => loadOfferSuccess(offerId, offer)),
        catchError((error) => of(loadOfferError(error)))
      )
    })
  )
const createCheckoutEpic$: CheckoutEpic = (action$, state$) =>
  action$.pipe(
    ofType<CreateCheckout>(CheckoutActionKeys.CREATE_CHECKOUT),
    switchMap(() => {
      const validatedCheckout = getValidatedCheckout(state$.value)
      if (validatedCheckout !== null) {
        return of(createCheckoutSuccess(validatedCheckout))
      }
      return LicensingApi.createCheckout({
        checkout: {
          lang: i18n.language,
          newsletterAccepted: isNewsletterAccepted(state$.value),
          termsAccepted: isTermsAccepted(state$.value),
          productIds: Object.keys(getSelectedProducts(state$.value)),
          offerId: getOfferId(state$.value)!,
          contact: getContact(state$.value),
        },
      }).pipe(
        map((validatedCheckout) => createCheckoutSuccess(validatedCheckout)),
        catchError((error) => of(createCheckoutError(error)))
      )
    })
  )

const redirectToConfirmationEpic$: CheckoutEpic = (action$) =>
  action$.pipe(
    ofType<StoreContactDetails>(CheckoutActionKeys.STORE_USER_DATA),
    tap(() => history.push(checkoutPathSummary())),
    ignoreElements()
  )

const redirectAfterCheckoutEpic$: CheckoutEpic = (action$, state$) =>
  action$.pipe(
    ofType<CreateCheckoutSuccess>(CheckoutActionKeys.CREATE_CHECKOUT_SUCCESS),
    tap(() => {
      const offer = getOffer(state$.value)
      if (offer?.type === OfferType.PROD) {
        history.push(checkoutPathNextSteps())
      } else {
        history.push(checkoutPathDownloads())
      }
    }),
    ignoreElements()
  )

const loadTermsEpic$: CheckoutEpic = (action$, state$, {ajax}) =>
  action$.pipe(
    ofType<LoadTerms>(CheckoutActionKeys.LOAD_TERMS),
    switchMap((action) => {
      const {productId} = action
      if (!productId) {
        return of(loadTermsError(new Error('Missing productId')))
      }
      return ajax({
        method: 'GET',
        url: `/api/terms/${productId}`,
        responseType: 'text',
      }).pipe(
        map((ajaxResponse) => loadTermsSuccess(ajaxResponse.response)),
        catchError((error) => of(loadTermsError(error)))
      )

      // does not work because terms is always null :(
      // return LicensingApi.getTerms({productId}).pipe(
      //   map((terms) => {
      //     console.log('terms', terms)
      //     return loadTermsSuccess(terms)
      //   }),
      //   catchError((error) => of(loadTermsError(error)))
      // )
    })
  )

export default [
  loadProductsEpic$,
  createCheckoutEpic$,
  redirectToConfirmationEpic$,
  redirectAfterCheckoutEpic$,
  loadTermsEpic$,
]
