"use strict";
// tslint:disable
/**
 * Indoqa Licensing
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var runtime_1 = require("../runtime");
/**
 * no description
 */
var LicensingApi = /** @class */ (function (_super) {
    __extends(LicensingApi, _super);
    function LicensingApi() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        /**
         * Create a new checkout based on the selected products
         */
        _this.createCheckout = function (_a) {
            var checkout = _a.checkout;
            runtime_1.throwIfNullOrUndefined(checkout, 'createCheckout');
            var headers = {
                'Content-Type': 'application/json',
            };
            return _this.request({
                path: '/api/checkout',
                method: 'POST',
                headers: headers,
                body: checkout,
            });
        };
        /**
         * Returns an offer containing info about the offering and the customer
         */
        _this.getOffer = function (_a) {
            var id = _a.id;
            runtime_1.throwIfNullOrUndefined(id, 'getOffer');
            return _this.request({
                path: '/api/offers/{id}'.replace('{id}', runtime_1.encodeURI(id)),
                method: 'GET',
            });
        };
        /**
         * Retrieve the terms
         */
        _this.getTerms = function (_a) {
            var productId = _a.productId;
            runtime_1.throwIfNullOrUndefined(productId, 'getTerms');
            return _this.request({
                path: '/api/terms/{productId}'.replace('{productId}', runtime_1.encodeURI(productId)),
                method: 'GET',
            });
        };
        return _this;
    }
    return LicensingApi;
}(runtime_1.BaseAPI));
exports.LicensingApi = LicensingApi;
