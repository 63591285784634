import {BaseCssProps, renderRebootCss} from '@indoqa/style-system'
import * as React from 'react'
import {ThemeProvider, useFela} from 'react-fela'
import {I18nextProvider, useTranslation} from 'react-i18next'
import {Redirect, Route, Switch} from 'react-router-dom'
import {setLocale} from 'yup'
import {
  checkoutPathContactDetails,
  checkoutPathDownloads,
  checkoutPathNextSteps,
  checkoutPathProducts,
  checkoutPathSummary,
} from '../checkout/checkout.paths'
import {ContactDetailsPage} from '../checkout/pages/ContactDetailsPage'
import {DownloadsPage} from '../checkout/pages/DownloadsPage'
import {NextStepsPage} from '../checkout/pages/NextStepsPage'
import {ProductsPage} from '../checkout/pages/ProductsPage'
import {SummaryPage} from '../checkout/pages/SummaryPage'
import {loadFonts} from '../commons-branded/fonts/FontRules'

import i18n from './i18n'
import theme from './theme'

const baseCssProps: BaseCssProps = {
  spacing: {
    space1: theme.spacing.space1,
    space2: theme.spacing.space2,
  },
  fontSizes: {
    text: theme.fontSizes.text,
    h1: theme.fontSizes.extraBig,
    h2: theme.fontSizes.veryBig,
    h3: theme.fontSizes.big,
  },
  fontStyles: {
    base: theme.fontStyles.base,
    alt: theme.fontStyles.alt,
  },
  links: {
    base: theme.colors.primaryDark,
    hover: theme.colors.primaryDark,
    active: theme.colors.primaryDark,
    visited: theme.colors.primaryDark,
  },
  colors: {
    text: theme.colors.text,
  },
}

const ValidationMessageProvider: React.FC = () => {
  const {t} = useTranslation(['validation'])

  setLocale({
    mixed: {
      required: () => t('required'),
    },
    string: {
      email: () => t('email'),
    },
  })

  return null
}

const App: React.FC = () => {
  const {renderer} = useFela()

  React.useLayoutEffect(() => {
    renderRebootCss(renderer, baseCssProps)
    loadFonts(renderer)
  }, [renderer])

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <ValidationMessageProvider />
        <Switch>
          <Route exact path={checkoutPathProducts()} component={ProductsPage} />
          <Route exact path={checkoutPathContactDetails()} component={ContactDetailsPage} />
          <Route exact path={checkoutPathSummary()} component={SummaryPage} />
          <Route exact path={checkoutPathDownloads()} component={DownloadsPage} />
          <Route exact path={checkoutPathNextSteps()} component={NextStepsPage} />
          <Redirect to={checkoutPathProducts()} />
        </Switch>
      </I18nextProvider>
    </ThemeProvider>
  )
}

export default App
