import {Box} from '@indoqa/style-system'
import {Product} from 'licensing-client-typescript'
import * as React from 'react'
import {useTranslation} from 'react-i18next'
import {useCurrentLanguage} from '../../commons/hooks/useCurrentLanguage'

interface Props {
  product: Product
  selectProduct?: (product: Product) => void
}

const formatters = {
  en: new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  }),
  de: new Intl.NumberFormat('de-AT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  }),
}

export const ProductName: React.FC<Props> = ({product, selectProduct}) => {
  const {t} = useTranslation(['others', 'forms'])
  const lang = useCurrentLanguage()
  const price = formatters[lang].format(product.price)
  if (product.demo) {
    return (
      <Box pb={1}>
        {product.name}
        <br />
        <small>
          {t('demoVersion')} {t('validFor')} {t(`period_${product.validity.unit}`, {count: product.validity.amount})}
        </small>
      </Box>
    )
  }
  return (
    <Box pb={1}>
      {product.name}
      <br />
      <small>
        {t('for')} {t(`period_${product.validity.unit}`, {count: product.validity.amount})}&nbsp;
        {t('at')} {price}
      </small>
    </Box>
  )
}
